<template>
  <footer class="footer">
    <div class="copyright-wrap">
      <img src="../../assets/images/bbs/wpzys_logo.png" class="logo" />
      <p>
        本站不储存任何资源，所有资源均来自网友自愿分享，如有侵权请<a
          :href="`http://report.3453k.cn?fid=${id}`"
          target="_blank"
          style="color: red"
          >点击举报</a
        >
      </p>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      id: this.$route.params.id,
      year: new Date().getFullYear(),
    }
  },
}
</script>
<style lang="scss" scoped>
.footer {
  background: #fff;
  padding: 20px;
  color: #333;
  .copyright-wrap {
    font-size: 12px;
    margin: 0 auto;
    width: 700px;
    max-width: 100%;
    text-align: center;
    img {
      height: 50px;
    }
    p {
      margin-bottom: 5px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
