<template>
  <header class="header">
    <div class="header-inner">
      <div class="left">
        <img src="../../assets/images/bbs/wpzys_logo.png" class="logo" />
        <div>
          网盘资源社<br />
          努力做有价值的资源论坛。
        </div>
      </div>

      <div class="right">
        <van-icon name="search" size="30" />
      </div>
    </div>
  </header>
</template>
<script>
export default {
  data() {
    return {
      keyword: "",
    }
  },
  mounted() {},
  methods: {
    submit() {
      return this.$message.warning("登录后才可以搜索")
    },
    async showLogin() {
      return this.$message.warning("当前尚未开放注册")
    },
  },
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_base.scss";
@import "@/assets/scss/_var.scss";
.header {
  padding: 5px 0;
  background-color: #fff;
  z-index: 10;
  position: sticky;
  top: 0;
  box-shadow: 0 0 1px 1px rgba(28, 28, 32, 0.05),
    0 8px 24px rgba(28, 28, 32, 0.06);
  .header-inner {
    width: 750px;
    margin: 0 auto;
    display: flex;
    max-width: 100%;
    min-height: 36px;
    justify-content: space-between;
    align-items: center;
    color: #999;
    font-weight: bold;
    .right,
    .left {
      display: flex;
      align-items: center;
    }
    .van-icon {
      cursor: pointer;
    }
    .logo {
      height: 50px;
      margin-right: 10px;
    }
  }
}
@media (max-width: 700px) {
  .header {
    padding: 5px 10px;
    .header-inner {
      .logo {
        height: 30px;
      }
      .search-form {
        .input {
          width: 150px;
        }
      }
    }
  }
}
</style>
