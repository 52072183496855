import { request } from "../api/_base";
import { FILTER } from "../constants";

export const getDetail = async ({ id, size = 15, ip, type, parent }) => {
  return await request({
    url: "/api/v1/pan/detail/bbs",
    params: {
      id: id,
      size: size,
      ip,
      type,
      parent,
    },
    headers: {
      "X-Authorization": process.server ? "" : localStorage.getItem("token"),
    },
  });
};
