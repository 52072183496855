<template>
  <div class="detail-page">
    <HeaderComponent />
    <div class="detail-page-main">
      <div class="loading-wrap" v-if="loading">
        <lottie-player
          class="animation"
          src="https://res.yimiaopan.com/pan/lottie/search.json"
          background="transparent"
          speed="0.4"
          loop
          autoplay
        />
      </div>
      <template v-if="detail && detail.filename">
        <div class="detail-page-inner">
          <h1>{{ detail.filename }}</h1>
          <div class="post-wrap">
            <div class="left">
              <div class="post-label">
                <img src="../../assets/images/bbs/pan.png" />
                文件/资源
              </div>
              <p class="createtime">发布于{{ detail.utime }}</p>
            </div>
            <div class="right">
              <van-icon name="eye-o" />639 <van-icon name="comment-o" /> 0
            </div>
          </div>
          <div class="userinfo">
            <div class="left">
              <img :src="avatar" class="avatar" />
              <div class="name-wrap">
                <h4 class="username">
                  {{ nickname }}
                </h4>
                <svg id="v101" viewBox="0 0 2560 1024">
                  <path
                    d="M153.6 0h2252.8c87.04 0 153.6 66.56 153.6 153.6v716.8c0 87.04-66.56 153.6-153.6 153.6H153.6C66.56 1024 0 957.44 0 870.4V153.6C0 66.56 66.56 0 153.6 0z"
                    fill="#FFF2E5"
                  ></path>
                  <path
                    d="m773.12 281.6-51.2 384h307.2l-5.12 76.8H614.4c-15.36 0-25.6-5.12-35.84-15.36-10.24-10.24-15.36-25.6-15.36-35.84l46.08-378.88-25.6-30.72h189.44zm599.04 117.76h148.48L1331.2 747.52h-153.6l-87.04-312.32-25.6-30.72h163.84l46.08 194.56 97.28-199.68zm215.04 250.88h122.88l-15.36 92.16h-122.88l15.36-92.16zm363.52 92.16h-153.6l51.2-368.64-61.44 10.24 10.24-87.04 220.16-30.72-66.56 476.16z"
                    fill="#FF8000"
                  ></path>
                </svg>
              </div>
            </div>
            <button @click="showLogin">关注TA</button>
          </div>
          <div class="detail-content">
            <div
              class="detail-wrap"
              v-if="detail.filelist && detail.filelist.length > 1"
            >
              <template v-for="(detailItem, detailIndex) in detail.filelist">
                <p
                  class="detail-item"
                  v-if="detailIndex > 0"
                  :key="`detail${detailIndex}`"
                  v-html="detailItem.filename"
                />
              </template>
            </div>
            <p class="download-link">
              <a :href="detail.url" target="_blank">{{ detail.url }}</a>
              <br />
              <span v-if="pwd">提取码：{{ pwd }}</span>
            </p>
            <div class="tag-wrap">
              <el-tag type="info" color="#0066ff"
                ><i class="el-icon-star-off"></i>收藏</el-tag
              >
              <el-tag type="info" color="#0066ff"
                ><i class="el-icon-thumb"></i>点赞</el-tag
              >
            </div>
            <div class="signature">
              <span> 这家伙太懒了，什么也没留下。 </span>
            </div>
            <div class="copyright">
              <p class="tit">#免责声明#</p>
              <p>
                本站不存储任何实质资源，该帖为网盘用户发布的网盘链接介绍帖,本文内所有链接指向的云盘网盘资源，其版权归版权方所有！其实际管理权为帖子发布者所有，本站无法操作相关资源。如您认为本站任何介绍帖侵犯了您的合法版权，请点击<a
                  style="
                  color: #d6064c;
                  cursor: pointer;
                  background-color: rgba(194, 41, 46, 0.1);
                  padding-left: 10px;
                  padding-right: 10px;
                  border-radius: 0.35rem;
                  text-decoration: none;
                  padding-top: 5px;
                  padding-bottom: 5px;
                  margin-left: 5px;
                "
                  :href="`http://report.3453k.cn?fid=${id}&type=${type}`"
                  target="_blank"
                  ><u style="text-decoration: none">版权投诉</u></a
                >
                进行投诉，我们将在确认本文链接指向的资源存在侵权后，立即删除相关介绍帖子！
              </p>
            </div>
          </div>
        </div>
        <div class="comment-wrap">
          <h4>最新回复</h4>

          <div class="comment-input">
            <div class="left">
              <img src="../../assets/images/bbs/wpzys_mlogo.png" />
            </div>
            <div class="right">
              <p>网盘资源社-优质云盘网盘资源分享社区论坛！</p>
              <div class="login-wrap">
                <button>立即登录</button>
              </div>
            </div>
          </div>
          <div class="button-wrap">
            <button @click="showLogin">回帖</button>
          </div>
        </div>
      </template>
      <div class="no-result" v-if="!loading && (!detail || !detail.filename)">
        <p>内容已被管理员删除！</p>
      </div>
    </div>
    <FooterComponent style="margin-top: 20px" />
  </div>
</template>
<script>
import HeaderComponent from "./Header.vue";
import FooterComponent from "./Footer.vue";
import { getDetail } from "../../api/search";
import { randomAvatar, randomNickname } from "../../utils/index";
const formatUrl = (url) => {
  if (!url) {
    return "";
  }
  const index = url.lastIndexOf("http");
  if (index > 0) {
    return url.slice(index);
  } else {
    return url;
  }
};

export default {
  components: {
    HeaderComponent,
    FooterComponent,
  },
  data: () => ({
    id: "",
    pwd: "",
    url: "",
    type: "",
    parent: "",
    detail: null,
    loading: true,
    avatar: randomAvatar(),
    nickname: randomNickname(),
  }),
  async mounted() {
    this.id = this.$route.params.id;
    this.type = this.$route.query.type;
    this.parent = this.$route.query.parent || "";
    if (this.id) {
      await this.getResourceDetail();
    }
  },
  methods: {
    async showLogin() {
      return this.$message.warning("暂未开放评论");
    },
    async getResourceDetail() {
      this.loading = true;
      if (this.id) {
        this.detail = await getDetail({
          id: this.id,
          type: this.type,
          parent: this.parent,
          size: 15,
        });
        if (this.detail && this.detail.filename) {
          this.pwd = this.detail.pwd;
        }
        if (this.detail && this.detail.url) {
          this.url = formatUrl(this.detail.url);
          this.getResourceUrl();
        }
      }
      this.loading = false;
    },

    async getResourceUrl() {
      if (
        this.type === "aliyundrive" &&
        this.parent &&
        this.parent != "undefined"
      ) {
        this.url = `${this.url}/folder/${this.parent}`;
      }
      if (this.pwd) {
        if (this.url.lastIndexOf("?") > -1) {
          this.url = `${this.url}&pwd=${this.pwd}`;
        } else {
          this.url = `${this.url}?pwd=${this.pwd}`;
        }
      }

      if (this.url.lastIndexOf("?") > -1) {
        this.url = `${this.url}&entry=dalipan`;
      } else {
        this.url = `${this.url}?entry=dalipan`;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_var.scss";
.detail-page {
  min-height: 100vh;
  background-color: #f4f7fc;
  .detail-page-main {
    min-height: calc(100vh - 220px);
  }
}
button {
  cursor: pointer;
  background: #0066ff;
  border: 0.5px solid #0066ff;
  border-radius: 6px;
  color: #fff;
  padding: 5px 10px;
  transition: opacity 0.2s;
  position: relative;
  cursor: pointer;
  font-size: 12px;
  margin: 0;
  white-space: nowrap;
}
.no-result {
  width: 750px;
  max-width: 100%;
  margin: 15px auto;
  background-color: #fff;
  padding: 100px 20px;
  border: none;
  border-radius: 6px;
  font-size: 30px;
  text-align: center;
  box-shadow: 0px 0px 10px rgba(26, 26, 26, 0.1);
  transition: box-shadow 0.5s ease-in-out;
}
.detail-page-inner {
  width: 750px;
  max-width: 100%;
  margin: 15px auto;
  background-color: #fff;
  padding: 20px;
  border: none;
  border-radius: 6px;
  box-shadow: 0px 0px 10px rgba(26, 26, 26, 0.1);
  transition: box-shadow 0.5s ease-in-out;
  h1 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1.3rem;
    line-height: 1.4;
  }
  .post-wrap {
    margin: 15px 0;
    display: flex;
    justify-content: space-between;
    .left,
    .right {
      font-size: 12px;
      display: flex;
      align-items: center;
      color: rgba(134, 142, 150, 0.8);
      font-weight: bold;
    }
    .right {
      font-weight: 400;
      .van-icon {
        margin-left: 8px;
        margin-right: 2px;
      }
    }
    .post-label {
      padding: 5px 10px;
      border-radius: 6px;
      margin-right: 10px;
      background: rgba(0, 102, 255, 0.18);
      font-weight: 600;
      color: #1979ff;
      display: flex;
      align-items: center;
      margin-right: 6px;
      img {
        width: 20px;
        margin-right: 3px;
      }
    }
  }
  .userinfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 -13px 0 -13px;
    background: #f7f8fa;
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #999;
    padding: 13px;
    .left {
      display: flex;
      flex-direction: row;
      img {
        height: 40px;
        border-radius: 4px;
        background: #fff;
        margin-right: 6px;
      }
      .name-wrap {
        svg {
          height: 16px;
          margin-top: 4px;
        }
      }
    }
  }
  .tag-wrap {
    margin-top: 40px;
    text-align: center;
    .el-tag {
      margin-right: 15px;
      font-size: 13px;
      color: #fff;
      i {
        margin-right: 5px;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .signature {
    overflow: hidden;
    margin-bottom: 10px;
    margin-top: 15px;
    padding-top: 20px;
    line-height: 1.6em;
    font-size: 1rem !important;
    color: #999;
    background: url("../../assets/images/bbs/sign.png") no-repeat 0 0;
  }
  .copyright {
    padding: 10px;
    border-radius: 5px;
    color: #777;
    font-size: 12px;
    font-weight: bold;
    background-color: #f6f6f6;
    .tit {
      margin-bottom: 5px;
      font-size: 15px;
      font-weight: 700;
      color: #007bf5;
      text-align: center;
      letter-spacing: 20px;
    }
    p {
      line-height: 25px;
    }
  }
  .detail-content {
    border-top: 1px solid #f6f6f6;
    padding: 15px 0;
  }
  .detail-wrap {
    color: #000;
    line-height: 1.8;
  }
  .download-link {
    margin-top: 10px;
    margin-bottom: 5px;
  }
  a {
    color: #4876db;
    border-bottom: 1px solid #e4ebf6;
  }
}
.comment-wrap {
  width: 750px;
  margin: 0 auto;
  padding: 20px;
  max-width: 100%;
  margin-bottom: 20px;
  background: #fff;
  box-shadow: 0 0px 10px rgba(26, 26, 26, 0.1);
  border-radius: 6px;
  .comment-input {
    margin-top: 15px;
    display: flex;
    .left {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 12px;
      margin-right: 15px;
      img {
        width: 40px;
        height: 40px;
      }
    }
    .right {
      color: #999;
      flex: auto;
      font-size: 12px;
      p {
        margin-bottom: 6px;
        font-weight: bold;
      }
      .login-wrap {
        height: 100px;
        border: 1px solid #ddd;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .button-wrap {
    margin-top: 10px;
    text-align: right;
    button {
      cursor: pointer;
      background: linear-gradient(
        129.12deg,
        #446dff 0%,
        rgba(99, 125, 255, 0.75) 100%
      );
      box-shadow: 0 16px 48px rgba(38, 38, 38, 0.12);
      backdrop-filter: blur(50px);
      height: 30px;
      line-height: 30px;
      padding: 0 10px;
      font-size: 12px;
      border: none;
      outline: none;
      color: #fff;
    }
  }
}
.loading-wrap {
  text-align: center;
  .animation {
    height: 160px;
  }
}
@media (max-width: 700px) {
  .detail-page-inner {
    .userinfo {
      flex-wrap: wrap;
    }
  }
}
</style>
